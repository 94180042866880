import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/global.css'
import request from "@/utils/request";
import Fragment from 'vue-fragment'


Vue.use(Fragment.Plugin)

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})


Vue.config.productionTip = false

Vue.use(ElementUI,{size:"mini"});//可配置具体选项

Vue.prototype.request=request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
